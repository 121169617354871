import { AlertOnHide, AlertType } from '../context/alertContext';

export interface IAlertMessageWithoutDispatch {
    alertTitle: string;
    alertTxt: string;
    alertType: AlertType;
    onHide: AlertOnHide;
    onHideCustom?: any;
    buttonName?: string;
    doBig?: boolean;
}
export interface IAlertMessage extends IAlertMessageWithoutDispatch {
    alertdispatch: React.Dispatch<any>;
}

export const showAlertMessage = (props: IAlertMessage) => {
    props.alertdispatch({
        type: 'SETSHOWALERTMESSAGE',
        alertMessage: {
            showModal: true,
            alertTitle: props.alertTitle,
            alertTxt: props.alertTxt,
            alertType: props.alertType,
            onHide: props.onHide,
            onHideCustom: props.onHideCustom,
            buttonName: props.buttonName ? props.buttonName : 'OK',
            doBig: props.doBig ? props.doBig : false,
        },
    });
};

export const resetShowAlertMessage = (alertdispatch: any) => {
    alertdispatch({
        type: 'RESETSHOWALERTMESSAGE',
    });
};
