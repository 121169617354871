import { useCallback, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import AppPropsContext from '../context/appPropsContext';
import { getAppProperties } from '../services/RestServices';
import { setDataFromAppProperties } from '../utils/pcpUtils';
import useScreenResolution from './useScreenResolution';

export const useAppProperties = () => {
    const { apstate, apdispatch } = useContext(AppPropsContext);

    const themeContext = useContext(ThemeContext);

    const screenSize = useScreenResolution();

    const getAndSetAppProperties = useCallback(async () => {
        const propRes = await getAppProperties();
        if (propRes) {
            setDataFromAppProperties(propRes, apdispatch);
        } else {
            // setError(true);      //TODO: add error handling
        }
    }, [apdispatch]);

    const getImpressum = () => {
        const impressum = {
            impressumLink: apstate.impressum.impressumLink,
            datenschutzLink: apstate.impressum.datenschutzLink,
            textColor: themeContext.button.secondary.color,
        };
        return impressum;
    };

    const getCountDaysToVisualize = () => {
        const MAX_DAYS_SMALL_DEVICES = 3;
        const MAX_SCREEN_WIDTH_SMALL_DEVICES = 414;

        if (!apstate.countDaysToVisualize) {
            return 0;
        } else if (screenSize.width >= 768 && screenSize.width < 890) {
            return MAX_DAYS_SMALL_DEVICES;
        } else if (screenSize.width > MAX_SCREEN_WIDTH_SMALL_DEVICES) {
            return apstate.countDaysToVisualize;
        } else if (apstate.countDaysToVisualize && apstate.countDaysToVisualize < MAX_DAYS_SMALL_DEVICES) {
            return apstate.countDaysToVisualize;
        } else {
            return MAX_DAYS_SMALL_DEVICES;
        }
    };

    /**
     * use extendedPhysicians in Booking (physicians and timeslots in one step)
     *
     * @returns true if extendedPhysicians is true and countDaysToVisualize is greater than 0
     */
    const isExtendedPhysicians = () => {
        if (
            apstate.extendedPhysicians &&
            apstate.extendedPhysicians === true &&
            apstate.countDaysToVisualize &&
            apstate.countDaysToVisualize > 0
        ) {
            return true;
        }

        return false;
    };

    const isFullscreenMode = () => {
        return apstate.fullscreenMode;
    };

    const enableFullscreenMode = () => {
        apdispatch({ type: 'SET_FULLSCREEN_MODE', fullscreenMode: true });
    };

    const disableFullscreenMode = () => {
        apdispatch({ type: 'SET_FULLSCREEN_MODE', fullscreenMode: false });
    };

    return {
        getAndSetAppProperties,
        getImpressum,
        isExtendedPhysicians,
        isFullscreenMode,
        enableFullscreenMode,
        disableFullscreenMode,
        getCountDaysToVisualize,
    };
};
